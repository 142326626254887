import { json, type LinksFunction, type MetaFunction } from "@remix-run/node";
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import tailwindStyleSheetUrl from "./styles/tailwind.css?url";
import fontsStyleSheetUrl from "./styles/satoshi.css?url";

export const links: LinksFunction = () => {
  return [
    // load the google fonts
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
      crossOrigin: "anonymous",
    } as const, // necessary to make typescript happy
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Marcellus&display=swap",
    },
    {
      rel: "alternate icon",
      type: "image/png",
      href: "/favicons/favicon-32x32.png",
    },
    { rel: "apple-touch-icon", href: "/favicons/apple-touch-icon.png" },
    {
      rel: "manifest",
      href: "/site.webmanifest",
      crossOrigin: "use-credentials",
    } as const,
    //These should match the css preloads above to avoid css as render blocking resource
    // { rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.svg" },
    { rel: "stylesheet", href: fontsStyleSheetUrl },
    { rel: "stylesheet", href: tailwindStyleSheetUrl },
  ].filter(Boolean);
};

export const meta: MetaFunction = () => {
  // return [
  //   { title: "Louvelle" },
  //   { name: "description", content: "The New Rotation" },
  // ];
  const metaDescription =
    "Coming Spring 2025, by invitation. Add your email to the waitlist!";
  const metaDomain = "louvelle.app";
  const metaTitle = "Louvelle";
  const metaImage = "https://louvelle.app/og-image-v4.jpg";
  return [
    { title: "Louvelle" },
    {
      name: "description",
      content: metaDescription,
    },
    { property: "og:url", content: `https://${metaDomain}` },
    { property: "og:type", content: "website" },
    { property: "og:title", content: metaTitle },
    {
      property: "og:description",
      content: metaDescription,
    },
    { property: "og:image", content: metaImage },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:domain", content: metaDomain },
    { property: "twitter:url", content: `https://${metaDomain}` },
    { property: "twitter:title", content: metaTitle },
    { property: "twitter:description", content: metaDescription },
    { property: "twitter:image", content: metaImage },
  ];
};

export async function loader() {
  const stage = process.env.STAGE || "dev";
  return json({ stage });
}

function Document({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className="h-full overflow-x-hidden">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <Meta />
        <Links />
      </head>
      <body className=" min-h-[100svh] border-0 border-red-500 border-dashed">
        {children}
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}

export default function App() {
  const { stage } = useLoaderData<typeof loader>();
  return (
    <Document>
      <div className="max-w-full min-h-[100dvh] overscroll-contain overflow-x-hidden ">
        <div className="lg:m-auto lg:max-h-svh lg:max-w-screen-2xl lg:overflow-y-hidden overflow-y-auto border-0 border-dashed border-red-500 ">
          <div className="lg:h-[calc(100svh-0px)] w-full border-0 border-orange-300">
            {stage !== "production" && (
              <h1 className="p-4 text-lg text-blue-500">
                stage: <span className="font-extrabold italic">{stage}</span>
              </h1>
            )}
            <Outlet />
          </div>
        </div>
        <footer className="md:fixed bottom-0 flex w-full flex-col justify-end ">
          <div className="border-0 border-pink-500 border-dashed w-full flex flex-row justify-center md:justify-end pb-4">
            <Link
              to="/privacy"
              className="p-4 text-sm"
              title="Privacy Policy"
              aria-label="Privacy Policy"
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms"
              className="p-4 text-sm"
              title="Terms and Conditions"
              aria-label="Terms and Conditions"
            >
              Terms and Conditions
            </Link>
            <Link
              to="https://louvelle.zohorecruit.com/"
              className="p-4 text-sm"
              title="Careers"
              aria-label="Careers"
            >
              Careers
            </Link>
            <Link
              to="https://louvelle.zohodesk.com/"
              className="p-4 text-sm"
              title="FAQ"
              aria-label="FAQ"
            >
              FAQ
            </Link>
          </div>
          <div className="fixed bottom-0 bg-eggplant h-6 w-full" />
        </footer>
      </div>
    </Document>
  );
}
